import React from 'react'
import './App.css';
import Navbar from './component/Navbar/Navbar';
import AboutSection from './component/AboutSection';
import ContactSection from './component/ContactSection';
import Footer from './component/Footer';
import Homesection from './component/Homesection';
import Servicessection from './component/Servicessection';


function Home() {
  return (
    <>
    <Navbar />
    <Homesection/>
    <AboutSection/>
    <Servicessection/>
    <ContactSection/>
    <Footer/>
    </>
  );
}

export default Home;
