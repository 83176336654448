import React from 'react'

function Footer() {
    return (
        <div>
            {/* <!-- footer section start --> */}
    <footer className="bg-dark text-light text-center " style={{height:"100px"}}>
        <span> <span className="pt-5 far fa-copyright"></span> 2021 All rights reserved.</span>
    </footer>
        </div>
    )
}

export default Footer
