import React from 'react'

function ContactSection() {
    return (
            // <!-- contact section start -->
    <section className="contact   text-dark" id="contact">
        <h2 className="text-center"><b> Contact Us</b></h2>
        <div className="row mt-5">
            <div className="col col-lg-2"></div>
            <div className="col col-lg-4 mt-5">
                <h3 className="text-danger">Get In Touch</h3>
                <div><b>Phone</b></div>
                <div>+6473088186</div>
                <div><b>Address</b></div>
                <div>2-34 Doncaster Ave Thornhill ON L3T 4S1</div>
            </div>
            <div className="col col-lg-4">

            <form action="#">
            <div class="form-row">
                <div class="form-group col-md-6">
                <label for="inputEmail4">First Name</label>
                <input type="text" class="form-control" id="inputEmail4" placeholder="First Name"/>
                </div>
                <div class="form-group col-md-6">
                <label for="inputPassword4">Last Name</label>
                <input type="text" class="form-control" id="inputPassword4" placeholder="Last Name"/>
                </div>
            </div>
            <div class="form-group">
                <label for="inputAddress">Email Adress</label>
                <input type="Email" class="form-control" id="inputAddress" placeholder="Email"/>
            </div>
            <div class="form-group">
                <label for="exampleFormControlTextarea1">Message</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
            </div>
            <button type="submit" class="btn btn-lg btn-info">Send Message</button>
            </form>

                </div>
            <div className="col col-lg-2"></div>
            
        </div>
    </section>

    )
}

export default ContactSection
