import React from 'react'
import './aboutsection.css'
import image1 from './images/about.jpg'

function Aboutsection() {
    return ( 
        <>
        <div>
        <div id="about" className="container-fluid text-dark  " >
            <div className="row">
                <div className="col col-lg-6 col-md-4 text-center ">
                <div className="image " >
                    <img src={image1} alt="" className="img p-5 " style={{height:"600px"}}/>
                </div>
                </div>
                <div className=" text col col-lg-6 col-md-4 text-center">
                <div className="text">
                <h1 className=" pb-5">About Us</h1>
                    <div className="heading">
                            <h5>Our Objectives</h5>
                    </div>
                </div>
                <div className="body-text">
                    <p>"We are wholesale dealer of car tires. one of our division works with retail 
                        clients for tires plus.<br/>
                        We also have mechanic shop (Auto body repair) which provides best services in town."

                    </p>
                </div>
                </div>
                
                </div>
            </div>
            </div>
  </>
    )
}

export default Aboutsection
