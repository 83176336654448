import React from 'react'
import { Link } from "react-scroll";
import './homesection.css'
import backgroundImage from './images/home.jpg'

function Homesection() {
  return (
    <section id="home" style={{backgroundImage:`url(${backgroundImage})`}}>
      <div className="contents container-fluid text-light"  >
        <div className="content">
          <h1 className="mb-3">Euro Auto Repair Ltd</h1>
          <h5>"Our company is a wholesale dealer of car tires,<br/> We also have a division that deals with retail clients for tires Plus<br/> they have a mechanic shop (Auto body repair)"  </h5>
        </div>
        <div className="">
        <button className="btn btn-lg bg-dark shadow-lg bg-outline-dark rounded-pill text-light">
        <Link className="nav-link" activeClass="active" to="about" spy={true} smooth={true} offset={-86} duration={500}>
         <h2><b><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
          </svg> Welcome
         </b></h2>
          </Link>
          </button>
        </div>
      </div>
    </section>
  )
}

export default Homesection
