import React from 'react'
import image1 from './images/service-1.jpg'
import image2 from './images/service-2.jpg'
import image3 from './images/service-3.jpg'
import image4 from './images/service-4.jpg'
import image5 from './images/service-5.jpg'
import image6 from './images/service-6.jpg'

function Servicessection() {
    return (
        <>

            <div className="services-section mt-5 bg-dark" id="service" >
                <div className="inner-width p-5">
                    <h1 className="section-title text-light text-center ">Our Services</h1>
                </div>
                <div className="Container">
                    <div className=" row text-center ">
                        <div className="col col-lg-2"></div>
                        <div className="col col-lg-8 row text-center text-light">
                            <div className="col col-lg-3 mt-2 p-1"><h5>DEALS WITH RETAIL CLIENTS</h5></div>
                            <div className="col col-lg-3 mt-2 p-1"><h5>WHOLESALE DEALERS </h5></div>
                            <div className="col col-lg-3 mt-2 p-1"><h5>MECHANIC SHOP</h5></div>
                            <div className="col col-lg-3 mt-2 p-1"><h5>BEST DEALINGS SERVICES</h5></div>
                        </div>
                        <div className="col col-lg-2"></div>
                    </div>

                    <div className=" row text-center mt-4 ">
                        <div className="col col-lg-2"></div>
                        <div className="col col-lg-8 row text-center text-black">
                            <div className="col col-lg-4 mt-2  p-1"><img src={image1} alt="" style={{ height: "400px", width: "400px" }} /></div>
                            <div className="col col-lg-4 mt-2  p-1"><img src={image2} alt="" style={{ height: "400px", width: "400px" }} /></div>
                            <div className="col col-lg-4 mt-2 p-1"><img src={image3} alt="" style={{ height: "400px", width: "400px" }} /></div>
                            <div className="col col-lg-4 mt-2 mb-5 p-1"><img src={image4} alt="" style={{ height: "400px", width: "400px" }} /></div>
                            <div className="col col-lg-4 mt-2 mb-5 p-1"><img src={image5} alt="" style={{ height: "400px", width: "400px" }} /></div>
                            <div className="col col-lg-4 mt-2 mb-5 p-1"><img src={image6} alt="" style={{ height: "400px", width: "400px" }} /></div>
                        </div>
                        <div className="col col-lg-2"></div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Servicessection
